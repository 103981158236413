<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col sm="12" style="position: relative;">
        <div>
          <v-card class="mx-auto my-4" max-width="90%">
            <v-card-title
              class="title justify-center grey--text text--darken-1 grey lighten-2"
              style="border-bottom: 1px #ff9800 solid !important;"
              >MAP SETTINGS</v-card-title
            >
            <v-row no-gutters class="grey lighten-4 py-2" align="center">
              <v-col sm="6">
                <div class="px-2">
                  Unit of measure  
                </div>
              </v-col>             
              <v-col sm="6">
                <div class="px-2">
                  <v-select
                    v-model="maps_uom.id"
                    :items="items_uom"
                    item-value="id"
                    item-text="text"
                    menu-props="auto"
                    hide-details
                    label="Select"
                    single-line
                    color="orange"
                    dense
                  ></v-select>
                </div>
              </v-col>
            </v-row>  
            <v-row no-gutters class="grey lighten-4 py-2" align="center">          
              <v-col sm="6">
                <div class="px-2">
                  Route type
                </div>
              </v-col>                       
              <v-col sm="6">
                <div class="px-2">
                  <v-select
                    v-model="maps_type.id"
                    :items="items_type"
                    item-value="id"
                    item-text="text"
                    menu-props="auto"
                    hide-details
                    label="Select"
                    single-line
                    color="orange"
                    dense
                  ></v-select>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-4 py-2" align="center">          
              <v-col sm="6">
                <div class="px-2">
                  Show truck restriction
                </div>
              </v-col>                       
              <v-col sm="6">
                <div class="px-2">
                  <v-select
                    v-model="maps_truck_restrictions.id"
                    :items="items_truck_restrictions"
                    item-value="id"
                    item-text="text"
                    menu-props="auto"
                    hide-details
                    label="Select"
                    single-line
                    color="orange"
                    dense
                  ></v-select>
                </div>
              </v-col>
            </v-row>            
            <v-card-actions class="justify-center grey lighten-2 py-2">
                <v-btn
                  color="orange darken-1"
                  class="white--text"
                  small
                  depressed
                  @click.prevent="updateSettings"
                  >Update</v-btn
                >
              </v-card-actions>          
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-layout row v-if="message.text">
      <v-flex xs12 sm6 px-1 ma-0>
        <app-alert
          :textAlert="message.text"
          :typeAlert="message.type"
          @dismissed="onDismissed()"
        ></app-alert>
      </v-flex>
    </v-layout>    
  </v-container>
</template>

<script>
export default {
  name: "Settings",
  data: () => ({
    maps_uom: { id: "KM", text: "KM" },
    maps_type: { id: "truck", text: "truck" },
    maps_truck_restrictions: { id: "no", text: "no" },
    items_uom: [
      { id: "KM", text: "KM" },
      { id: "miles", text: "miles" },
    ],
    items_type: [
      { id: "truck", text: "truck" },
      { id: "car", text: "car" },
    ],    
    items_truck_restrictions: [
      { id: "no", text: "no" },
      { id: "yes", text: "yes" },
    ],     
  }),
  computed: {
    message() {
      return this.$store.getters.message;
    },
  },
  mounted() {
    if (localStorage.getItem("maps_uom") !== null) {
      let current_uom = localStorage.getItem("maps_uom");
      this.maps_uom = { id: current_uom, text: current_uom };
    }
    if (localStorage.getItem("maps_type") !== null) {
      let current_type = localStorage.getItem("maps_type");
      this.maps_type = { id: current_type, text: current_type };
    }    
    if (localStorage.getItem("maps_truck_restrictions") !== null) {
      let current_truck_restrictions = localStorage.getItem("maps_truck_restrictions");
      this.maps_truck_restrictions = { id: current_truck_restrictions, text: current_truck_restrictions };
    }     
  },
  methods: {
    updateSettings() {
      this.$store.dispatch("updateSettings", {
        maps_uom: this.maps_uom.id,
        maps_type: this.maps_type.id,
        maps_truck_restrictions: this.maps_truck_restrictions.id,
      });
    },
    onDismissed() {
      this.$store.dispatch("clearMessage");
    },
  },
};
</script>
